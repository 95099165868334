<template>
  <div class="priceIndex">
    <div class="banner">
      <img src="@/assets/priceindex_img/banner.jpg" alt="" />
    </div>
    <div class="main f f-wrap">
      <div class="item mr20">
        <div class="img-box mb20">
          <a href="https://api.xjcec.com/third/third/ossGetFile/price_index_plan.pdf" download="download.txt">点击下载查阅</a>
          <img src="@/assets/priceindex_img/i1.png" alt="" />
        </div>
        <div class="img-box">
          <div class="info"> 
            <p>热线：0902-2305929</p>
            <p>邮箱：xjmtjyzx@xjcec.com</p>
          </div>
          <img src="@/assets/priceindex_img/i2.png" alt="" />
        </div>
      </div>
      <div class="item">
        <div class="item-card">
          <div class="title-box f f-jsb">
            <p>指数公告</p>
          </div>
          <div class="notice-list">
            <div
              class="notice-item f f-ac f-jsb"
              v-for="(item, index) in list"
              :key="index"
              @click="goDetail(item)"
            >
              <p class="notice-item_title f-hide" title="sss">
                {{ item.title }}
              </p>
              <p class="notice-item_time">{{ item.createTime | timeFarmat }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="item mr20 border">
        <p class="item-title">新疆动力煤指数表格</p>
        <el-table :data="tableData" width="100%">
          <el-table-column align="center" prop="title" label="指数名称" width="150">
          </el-table-column>
          <el-table-column align="center" prop="date" label="日期" width="100">
            <template slot-scope="scope">
              {{ scope.row.data.date }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="最新价格" width="80">
            <template slot-scope="scope">
              {{ scope.row.data.currentPrice }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="offerPrice" label="日环比"  width="80">
            <template slot-scope="scope">
				<span :style="{ color: getColor(scope.row.data.dayQoq) }">
					{{ scope.row.data.dayQoq }}
				</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="offerPrice" label="周环比"  width="80">
            <template slot-scope="scope">
				<span :style="{ color: getColor(scope.row.data.weekQoq) }">
					{{ scope.row.data.weekQoq }}
				</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="offerPrice" label="月环比"  width="80">
            <template slot-scope="scope">
				<span :style="{ color: getColor(scope.row.data.monthQoq) }">
					{{ scope.row.data.monthQoq }}
				</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="offerPrice" label="同比"  width="80">
            <template slot-scope="scope">
				<span :style="{ color: getColor(scope.row.data.yoy) }">
					{{ scope.row.data.yoy }}
				</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="item border">
        <div id="lineChart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { queryArticleList, getPageData } from "@/api/hy.js";
export default {
  data() {
    return {
      list: [],
      tableData: [],
      myChart: null,
      option: {
        title: {
          text: "新煤动力煤指数历史趋势",
          top: "%",
          left: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "10%",
          data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
        },
        grid: {
          top: "20%",
          left: "10%",
          right: "10%",
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Email",
            type: "line",
            stack: "Total",
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "Union Ads",
            type: "line",
            stack: "Total",
            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            name: "Video Ads",
            type: "line",
            stack: "Total",
            data: [150, 232, 201, 154, 190, 330, 410],
          },
          {
            name: "Direct",
            type: "line",
            stack: "Total",
            data: [320, 332, 301, 334, 390, 330, 320],
          },
          {
            name: "Search Engine",
            type: "line",
            stack: "Total",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
          },
        ],
      },
      hidefont: 9,
    };
  },
  filters: {
    timeFarmat(val) {
      if (!val) return "";
      return val.replace("T", " ");
    },
  },
  mounted() {
    console.log(1111);
    this.getArticleList();
    this.getData();
  },
  methods: {
    getData() {
      getPageData({ sign: "jgzs" }).then((ret) => {
        let list = ret.data.charts.find(e => e.sign == 'XJDLMZXJGZS')
        list.items.forEach(item => {
          item.data = JSON.parse(item.values[0].number)
        });
        this.tableData = list.items
        console.log(list.items, '-----list.items----')
        let pieData =  ret.data.charts.find(e => e.sign == 'XJDLMLSJGZS')
        this.setOption(pieData.items)
        this.initChart()
      });
    },
    setOption (data) {
      let legend = data.reduce((t, i) => {
        t.push(i.title)
        return t
      }, [])
      let xAxis = data[0].values.reduce((t, i) => {
        let data = JSON.parse(i.number)
        t.push(data.date)
        return t
      }, [])
      let series = data.reduce((t, i) => {
        t.push({
          name: i.title,
          type: "line",
          data: i.values.reduce((arr, val) => {
            let data = JSON.parse(val.number)
            arr.push(+data.currentPrice)
            return arr
          }, [])
        })
        return t
      }, [])
      this.option.legend.data = legend
      this.option.xAxis.data = xAxis
      this.option.series = series
    },
    initChart() {
      if (!this.myChart) {
        this.myChart = echarts.init(document.getElementById("lineChart"));
      }
      console.log(this.myChart, "3this.myChart--");
      this.myChart.setOption(this.option);
    },

    getArticleList() {
      queryArticleList({
        articleType: "182",
        pageNum: 1,
        pageSize: 1000,
        sortType: "timeDesc",
      }).then((ret) => {
        this.list = ret.data.list || [];
        console.log(this.tableData, "--- this.tableData---");
      });
    },
    goDetail(item) {
      console.log(item,'---item----')
      this.$router.push({
        path: "/priceIndex/detail",
        query: {
          articleId: item.articleId,
        },
      });
    },
	getColor(str) {
      if (str[0] == '+') {
        return 'red';
      } else if (str.length > 1 && str[0] == '-') {
        return 'green';
      } else {
        return '';
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#lineChart {
  height: 330px;
  width:  100%;
  background: white;
  border-radius: 10px;
}
// ::v-deep
.f-hide {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.f-hide2 {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical; //盒子中内容竖直排列
}
.f {
  display: flex;
}

.f1 {
  flex: 1;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-col {
  flex-direction: column;
}

.f-ac {
  align-items: center;
}

.f-ae {
  align-items: flex-end;
}

.f-jc {
  justify-content: center;
}

.f-jsb {
  justify-content: space-between;
}

.f-jsa {
  justify-content: space-around;
}
.mr20 {
  margin-right: 20px;
}
.banner {
  width: 100%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
.item {
  margin-bottom: 20px;
}
.item-title {
  font-size: 18px;
  margin-bottom: 20px;
}
.el-table{
  &::v-deep th{
    background: #F6F8FA !important;
    color: #9C9A97;
  }
  &::v-deep .el-table__body tr:nth-child(even){
    background-color: #F6F8FA !important;
  }
}
.border{
  background: white;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #DCDCDC;
}
.mb20 {
  margin-bottom: 20px;
}
.priceIndex {
  .main {
    width: 1440px;
    margin: 20px auto 0;
  }
  .img-box {
    width: 700px;
    height: 160px;
    position: relative;
    a{
      color: #FA7A22;
      font-size: 18px;
      position: absolute;
      right: 310px;
      bottom: 50px;
    }
    .info{
      position: absolute;
      right: 190px;
      bottom: 50px;
    }
    p{
      color: white;
      font-size: 18px;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      height: 100%;
      width: 100%;
    }
  }
  .item {
    display: inline-block;
    width: 700px;
  }
}
.item-card {
  .title-box {
    border: 1px solid #DCDCDC;
    border-radius: 10px 10px 0 0;
    border-bottom: none;
    padding: 17px;
    background: #F4F9FF;
    font-weight: bold;
  }
  .notice-list {
    padding: 30px;
    border: 1px solid #DCDCDC;
    border-radius: 0 0 10px 10px;
    height: 286px;
    overflow: auto;
    background: #FFFFFF;
    .notice-item {
      line-height: 45px;
    }
    .notice-item_title {
      cursor: pointer;
      &::before {
        content: "";
        display: inline-block;
        vertical-align: sub;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        border-radius: 50%;
        background: wheat;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 7px;
  background-color: #e1e7f1;
}

::-webkit-scrollbar-thumb:hover {
  cursor: auto;
  background-color: #f9fafc;
}
.priceIndex2{
  border-radius: 0;
}
</style>